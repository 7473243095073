<template>
  <div class="main" data-title="销售变更日志" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">销售变更日志</h2>
        <div class="head-search">
          <el-form class="form">
            <el-row :gutter="20">
              <el-col :span="5">
                <el-form-item label="原销售顾问">
                  <el-select filterable placeholder="请选择" v-model="key.old_seller_uid">
                    <template v-for="(item, key) in $store.state.userList">
                      <el-option
                        :key="key"
                        :label="item[0]"
                        :value="parseInt(key)"
                        v-if="item[1] == 2"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="新销售顾问">
                  <el-select filterable placeholder="请选择" v-model="key.new_seller_uid">
                    <template v-for="(item, key) in $store.state.userList">
                      <el-option
                        :key="key"
                        :label="item[0]"
                        :value="parseInt(key)"
                        v-if="item[1] == 2"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="客户姓名">
                  <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="key.realname"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="联系方式">
                  <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="key.mobile"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item class="label-block" label=" ">
                  <el-button @click="handleCurrentChange(1)" icon="el-icon-search" type="primary">搜索</el-button>
                  <el-button @click="resetSeachKey">重置</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <el-card class="main-content register-box" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">操作内容</div>
        <div class="jue-card-btns"></div>
      </div>

      <div class="table-wrapper">
        <el-table
          :data="dataList"
          :style="'width: '+$store.state.tableWidth"
          class="table"
          style="width: 100%"
        >
          <el-table-column label="客户姓名" prop="realname"></el-table-column>
          <el-table-column label="联系方式" prop="mobile"></el-table-column>
          <el-table-column label="原销售顾问" prop="old_seller_uid">
            <template slot-scope="scope">{{ $store.state.userList[scope.row.old_seller_uid][0] }}</template>
          </el-table-column>
          <el-table-column label="新销售顾问" prop="new_seller_uid">
            <template slot-scope="scope">{{ $store.state.userList[scope.row.new_seller_uid][0] }}</template>
          </el-table-column>
          <el-table-column label="操作人" prop="create_uid">
            <template slot-scope="scope">{{ $store.state.userList[scope.row.create_uid][0] }}</template>
          </el-table-column>
          <el-table-column label="备注" prop="memo"></el-table-column>
          <el-table-column label="时间" prop="created_at"></el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-pagination
          :page-size="key.count"
          :total="total"
          @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      key: {
        realname: '',
        mobile: '',
        old_seller_uid: '',
        new_seller_uid: '',

        page: 1,
        count: 15
      },
      dataList: []
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    resetSeachKey() {
      this.key.realname = ''
      this.key.mobile = ''
      this.key.old_seller_uid = ''
      this.key.new_seller_uid = ''
      this.getDataList()
    },
    //点击分页
    handleCurrentChange(val) {
      this.key.page = val
      this.getDataList()
    },
    getDataList() {
      if (!this.key.new_seller_uid) {
        delete this.key.new_seller_uid
      }
      if (!this.key.old_seller_uid) {
        delete this.key.old_seller_uid
      }
      if (!this.key.realname) {
        delete this.key.realname
      }
      if (!this.key.mobile) {
        delete this.key.mobile
      }
      this.$axios.post('/client/seller_change_lists', this.key).then(res => {
        if (res.data.code == 200) {
          this.dataList = res.data.data.list
          this.total = res.data.data.total
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.table-wrapper {
  margin: -20px -20px 0;
}

.form {
  /deep/ .el-form-item__label {
    padding: 0;
  }
  /deep/ .el-select,
  /deep/ .el-date-editor {
    width: 100%;
  }
}

.footer {
  text-align: right;
  padding: 20px 0;
}
</style>